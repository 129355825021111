<template>
  <el-dialog
    :visible.sync="isShow"
    :title="'Upload Result: ' + data.clientFilename"
    width="50%"
  >
    <div class="text-center">
      <el-form :model="data" ref="mainForm">
          <div v-if="data.isSuccess">
            <b-row lg="12">
              <b-col lg="4">
                <el-form-item :label="$t('Total Rows')" prop="totalRows">
                  <el-input v-model="data.result.totalRows" readonly/>
                </el-form-item>
              </b-col>

              <b-col lg="4">
                <el-form-item :label="$t('Success Rows')" prop="totalRows">
                  <el-input v-model="data.result.successRows" readonly/>
                </el-form-item>
              </b-col>

              <b-col lg="4">
                <el-form-item :label="$t('Error Rows')" prop="totalRows">
                  <el-input v-model="data.result.errorRows" readonly/>
                </el-form-item>
              </b-col>
            </b-row>

            <el-input type="textarea" :value="data.result.messages.join('\n')" :autosize="{ minRows: 5, maxRows: 10}" v-if="data.result.messages.length > 0"/>
        </div>
        <div v-else>
          <el-input type="textarea" :value="data.message" :autosize="{ minRows: 5, maxRows: 10}"/>
        </div>
      </el-form>

      <div slot="footer" class="dialog-footer mt-2 text-right">
        <b-button variant="primary" class="btn" @click="isShow = false;">
          {{ $t('OK') }}
        </b-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>

export default {
  data() {
    return {
      data: {},

      isShow: false,
    }
  },
  methods: {
    showResult(data) {
      this.data = data;

      this.isShow = true;
    },
  }
}
</script>