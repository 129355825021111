<template>
<div>
  <section>
      <AdvanceTable ref="advanceTable" :url="apis.erpIncomingActualReceivable" :height="window.height - 310" tableSize="sm" :columns="columns" :selectable="true" @row-selected="onRowSelected" v-loading="isLoading">
        <template #title>
            {{ $t("API Actual Receivable") }}
        </template>
        <template #button>
          <b-button variant="secondary" size="sm" class="mr-50" @click="openModalForCreateInvoice">
            <feather-icon icon="PlusIcon"/>
            <span class="align-middle">{{ $t("Create Invoice") }}</span>
          </b-button>

          <b-button variant="secondary" size="sm" class="mr-50" @click="openModalForUpload">
            <feather-icon icon="UploadIcon"/>
            <span class="align-middle">{{ $t("Upload Charge Item") }}</span>
          </b-button>
        </template>
        <template #cell(unitPrice)="row">
          {{ row.item.unitPrice }} {{ row.item.currency }}
        </template>
        <template #cell(amount)="row">
          {{ row.item.amount }} {{ row.item.currency }}
        </template>
        <template #cell(addTime)="row">
          {{ formatWithCurrentTimezone(row.item.addTime) }}
        </template>
        <template #cell(selection)="row">
          <input type="checkbox" :checked="row.rowSelected" style="pointer-events: none" :disabled="row.item.invoiceNumber !== null"/>
        </template>
        <template #head(selection)="row">
          <input type="checkbox" style="margin-top: 40px" ref="selectAll" @click="onSelectAllSelected">
        </template>
      </AdvanceTable>
  </section>

  <UploadModal ref="uploadView">
    <template v-slot:tip="scope">
      Only accepts xlsx files with size less than {{ $refs.uploadView ? $refs.uploadView.getMaxSizeForDisplay() : '' }}<br/>
      <a href="https://prod-kec-dashboard.s3.ap-east-1.amazonaws.com/public/Charge-Item-Upload-Template.xlsx" target="_blank" @click.stop>Download Template</a>
    </template>
  </UploadModal>

  <ExcelResultModal ref="excelResult"/>
  <InvoiceCreateModal ref="invoiceCreateView" :type="invoiceType" :profile="organizationProfile"></InvoiceCreateModal>
</div>
</template>

<script>
import { getAccessToken, getErpProfile } from "@/utils/account-localstorage";
import AdvanceTable from "../components/advanceTable/advanceTable.vue";
import { BButton, BModal } from "bootstrap-vue";
import apis from "@/configs/apis";
import UploadModal from "@/views/erp/components/UploadModal.vue";
import Prompt from "@/views/erp/mixins/Prompt";
import ExcelResultModal from "@/views/erp/components/ExcelResultModal.vue";
import axios from "axios";
import { formatWithCurrentTimezone } from "./utils/time";
import InvoiceCreateModal from "@/views/erp/components/InvoiceCreateModal.vue";

export default {
  mixins: [Prompt],
  components: {
    InvoiceCreateModal,
    ExcelResultModal,
    UploadModal,
    BButton, BModal,
    AdvanceTable,
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      invoiceType: 'INVOICE',
      organizationProfile: {},
      columns: [
        { key: "selection", width: "30"},
        { key: "customerName", modelName: "customerName", label: "Customer Name", width: "100", filtertype: "input", sortable: true },
        { key: "systemCode", modelName: "systemCode", label: "System Code", width: "100", filtertype: "input", sortable: true },
        { key: "accountCode", modelName: "accountCode", label: "Account Code", width: "100", filtertype: "input", sortable: true },
        { key: "billingNumber", modelName: "billingNumber", label: "Billing Number", width: "100", filtertype: "input", sortable: true },
        { key: "billingPeriod", modelName: "billingPeriod", label: "Billing Period", width: "100", filtertype: "input", sortable: true },
        { key: "chargeCode", modelName: "chargeCode", label: "Charge Code", width: "100", filtertype: "input", sortable: true },
        { key: "warehouseCode", modelName: "warehouseCode", label: "W/H Code", width: "100", filtertype: "input", sortable: true },
        { key: "unitPrice", modelName: "unitPrice", label: "Unit Price", width: "50", filtertype: "input", sortable: true },
        { key: "qty", modelName: "qty", label: "Qty", width: "50", filtertype: "input", sortable: true },
        { key: "amount", modelName: "amount", label: "Total Amount", width: "100", filtertype: "input", sortable: true },
        { key: "description", modelName: "description", label: "Description", width: "100", filtertype: "input", sortable: true },
        { key: "invoiceNumber", modelName: "invoiceNumber", label: "Invoice Number", width: "120", filtertype: "input", sortable: true },
        { key: "addTime", modelName: "addTime", label: "Add Time", width: "120" },
      ],
      productList: [],
      isLoading: false,
      invoice: {},
      selected: [],
    };
  },
  computed: {
    apis() {
      return apis
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {

  },
  methods: {
    formatWithCurrentTimezone,
    getAccessToken,
    getErpProfile,
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    show(item) {
      this.$router.push({
        name: 'erp-company-profile',
        params: { id: item.organizationProfileId },
        hash: '#invoice'
      })
    },

    openModalForUpload() {
      this.$refs.uploadView.showUpload('Upload Charge Item', 'CHARGE_ITEM', {maxSize: 10485760, allowedExtension: 'xlsx'}, (form) => {
        this.isLoading = true;

        axios.post(
          apis.erpProcessChargeItems,
          form
        ).then((response) => {
          this.$refs.advanceTable.loadList();
          this.$refs.uploadView.hideUpload();
          this.$refs.excelResult.showResult(response.data.data);
        }).catch(e => {
          console.log(e);
          this.promptError(e);
        }).finally(() => {
          this.isLoading = false;
        });
      });
    },

    onRowSelected(items) {
      let accountCodes = [... new Set(items.map((item) => item.accountCode))];

      let newIds = items.map((item) => item.id);
      let oldIds = this.selected.map((item) => item.id);
      let diffIds = newIds.filter(n => !oldIds.includes(n));

      // Don't allow more than one account code
      if (accountCodes.length > 1) {
        for (let rowIndex = 0; rowIndex < this.$refs.advanceTable.rows.length; rowIndex += 1) {
          if (this.$refs.advanceTable.rows[rowIndex]['id'] === diffIds[0]) this.$refs.advanceTable.$refs.advanceTable.unselectRow(rowIndex);
        }

        return false;
      }

      // Don't allow invoiced rows
      for (let rowIndex = 0; rowIndex < this.$refs.advanceTable.rows.length; rowIndex += 1) {
        if (this.$refs.advanceTable.rows[rowIndex]['invoiceNumber'] !== null) this.$refs.advanceTable.$refs.advanceTable.unselectRow(rowIndex);
      }

      this.selected = items;

      this.updateSelectAllState();
    },

    onSelectAllSelected() {
      if (this.$refs.selectAll.checked) {
        this.$refs.advanceTable.$refs.advanceTable.selectAllRows();
      } else {
        this.$refs.advanceTable.$refs.advanceTable.clearSelected();
      }
    },

    updateSelectAllState() {
      if (this.selected.length === 0) {
        this.$refs.selectAll.checked = false;
        this.$refs.selectAll.indeterminate = false;
      } else if (this.selected.length === this.$refs.advanceTable.rows.length) {
        this.$refs.selectAll.checked = true;
        this.$refs.selectAll.indeterminate = false;
      } else {
        this.$refs.selectAll.checked = true;
        this.$refs.selectAll.indeterminate = true;
      }
    },

    openModalForCreateInvoice() {
      let accountCodes = [... new Set(this.selected.map((item) => item.accountCode))];
      if (accountCodes.length <= 0) return;

      axios.get(apis.erpOrganizationProfile, {params: {accountCode: accountCodes[0]}})
        .then(response => {
          let profiles = response.data.data.data;
          if (profiles.length === 0) throw "Cannot find Company Profile with Account Code: " + accountCodes[0];

          this.$refs.invoiceCreateView.createInvoiceFromApiAccrualReceivable(profiles[0], 'INVOICE', this.selected);
        })
        .catch(e => {
          this.promptError(e);
        })
    }
  }
};
</script>

