<template>
  <el-dialog
    class="compact"
    :title="$t('Create ' + getTypeForDisplay())"
    :visible.sync="isShowCreateInvoice"
    width="80%"
    v-loading="isLoading"
  >
    <b-row>
      <b-col lg="4">
        <b-form-group :label="$t('Account Code')">
          <el-input v-model="newInvoice.accountCode" size="mini" disabled="disabled"/>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group :label="$t('Type')">
          <el-input v-model="newInvoice.type" size="mini" disabled="disabled"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="4">
        <b-form-group :label="$t('Currency Profile')">
          <el-select filterable size="mini" v-model="newInvoice.currencyProfileId" style="width: 100%" @change="loadCurrencies()">
            <el-option v-for="item in currencyProfiles" :key="item.id" :label="item.name + (item.deleteTime ? ' (Deleted)' : '')" :value="item.id" :disabled="item.deleteTime !== null" v-if="item.deleteTime === null || item.id === profile.currencyProfileId">
              {{ item.name }}
            </el-option>
          </el-select>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group :label="$t('Currency Code')">
          <el-select filterable size="mini" v-model="newInvoice.originalCurrency" style="width: 100%">
            <el-option v-for="item in currencies" :key="item" :label="item" :value="item">
              {{ item }}
            </el-option>
          </el-select>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group :label="$t('Billing Period (Month)')">
          <el-date-picker type="month" value-format="yyyy-MM-dd" v-model="newInvoice.billingPeriodMonth" size="mini" @change="onBillingPeriodMonthChange()" style="width: 100%"/>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="4">
        <b-form-group :label="$t('Billing Period (From)')">
          <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="newInvoice.billingPeriodStart" size="mini" disabled="disabled" style="width: 100%"/>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group :label="$t('Billing Period (To)')">
          <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="newInvoice.billingPeriodEnd" size="mini" disabled="disabled" style="width: 100%"/>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group :label="$t('Billing Period (Sun)')">
          <el-input v-model="newInvoice.billingPeriod" size="mini" disabled="disabled"/>
        </b-form-group>
      </b-col>
    </b-row>

    <div v-if="newInvoice && newInvoice.items && newInvoice.items.length > 0">
      <hr/>

      <h4>Items from API Actual Receivable</h4>

      <table class="table mb-2">
        <thead>
        <tr>
          <th>Account Code</th>
          <th>Charge Code</th>
          <th>Warehouse Code</th>
          <th>Unit Price</th>
          <th>Qty</th>
          <th>Original Amount</th>
          <th>Billing Period</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in newInvoice.items">
          <td>{{ item.accountCode }}</td>
          <td>{{ item.chargeCode }}</td>
          <td>{{ item.warehouseCode }}</td>
          <td>{{ item.unitPrice }} {{ item.currency }}</td>
          <td>{{ item.qty }}</td>
          <td>{{ item.amount }} {{ item.currency }}</td>
          <td>{{ item.billingPeriod }}</td>
          <td>{{ item.description }}</td>
        </tr>
        </tbody>
      </table>

      <hr/>

      <h4>Invoice Items from API Actual Receivable</h4>

      <table class="table mb-2">
        <thead>
        <tr>
          <th>Account Code</th>
          <th>Charge Code</th>
          <th>Warehouse Code</th>
          <th>Unit Price</th>
          <th>Qty</th>
          <th>Original Amount</th>
          <th>Billing Period</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(group, key) in invoiceItemsGroups">
          <td>{{ group[0].accountCode }}</td>
          <td>{{ group[0].chargeCode }}</td>
          <td>{{ group[0].warehouseCode }}</td>
          <td>{{ getGroupSum(group, 'amount').toFixed(2) }} {{ group[0].currency }}</td>
          <td>{{ 1 }}</td>
          <td>{{ getGroupSum(group, 'amount').toFixed(2) }} {{ group[0].currency }}</td>
          <td>{{ group[0].billingPeriod }}</td>
          <td>{{ group[0].description }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div v-if="isAccrualEnabled()">
      <hr/>

      <h4>Accruals</h4>

      <table class="table mb-2">
        <thead>
        <tr>
          <th>Accrual Number</th>
          <th>Charge Code</th>
          <th>Category</th>
          <th>Level 1</th>
          <th>Level 2</th>
          <th>Amount</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(accrual, i) in invoiceAccruals">
          <td>{{ accrual.accrualNumber }}</td>
          <td>{{ accrual.chargeCode }}</td>
          <td>{{ accrual.chargeCodeCategory }}</td>
          <td>{{ accrual.chargeCodeLevel1 }}</td>
          <td>{{ accrual.chargeCodeLevel2 }}</td>
          <td>{{ accrual.totalAmount }} {{ accrual.currency }}</td>
          <td>
            <el-link @click="removeAccrual(i)"><i class="el-icon-delete"></i> Remove</el-link>
          </td>
        </tr>
        </tbody>
      </table>

      <el-select v-model="newAccrual" value-key="id" filterable>
        <el-option v-for="item in companyAccruals" :key="item.accrualNumber" :label="item.accrualNumber" :value="item" v-if="item.deleteTime === null" :disabled="(item.chargeCodeEntity && item.chargeCodeEntity.type === 'AP') || item.confirmTime !== null">
          {{ item.accrualNumber }} / Type: {{ item.chargeCodeEntity && item.chargeCodeEntity.type ? item.chargeCodeEntity.type : 'Unspecified' }} / Charge Code: {{ item.chargeCode }} / Warehouse Code: {{ item.warehouseCode }}
        </el-option>
      </el-select>

      <el-button type="primary" class="ml-1" @click="addAccrual(newAccrual)">Add New Accrual</el-button>
    </div>

    <div>
      <div class="w-100 d-flex justify-content-end">
        <b-button variant="primary" class="btn" @click="submitCreateInvoice()">
          {{ $t('Create') }}
        </b-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { isAccrualEnabled, MAX_PAGE_SIZE } from "@/views/erp/utils/system";
import axios from "axios";
import apis from "@/configs/apis";
import Invoice from "@/views/erp/mixins/Invoice";
import _ from "lodash";
import Prompt from "@/views/erp/mixins/Prompt";
import { EventBus } from "@/views/erp/utils/event-bus";
import { BButton } from "bootstrap-vue";
import moment from "moment/moment";

export default {
  components: { BButton },
  mixins: [Prompt, Invoice],
  computed: {
    invoiceItemsGroups: function () {
      // Group newInvoice.items by chargeCode, warehouseCode, currency, billingPeriod
      return _.groupBy(this.newInvoice.items, (item) => {
        return item.chargeCode + ',' + item.warehouseCode + ',' + item.currency + ',' + item.billingPeriod;
      });
    }
  },
  data() {
    return {
      profile: {},
      type: null,
      isShowCreateInvoice: false,
      newInvoice: {},
      currencies: [],
      currencyProfiles: [],

      newAccrual: null,
      companyAccruals: [],
      invoiceAccruals: [],

      isLoading: false
    }
  },
  mounted() {
    this.loadCurrencyProfiles();
    this.loadCurrencies();
  },
  methods: {
    isAccrualEnabled,
    loadCurrencyProfiles() {
      axios.get(apis.erpCurrencyProfiles, {params: {isShowDeleted: 'true'}})
        .then(response => {
          this.currencyProfiles = response.data.data.data;
        })
    },

    loadCurrencies() {
      axios.get(apis.erpCurrencyProfileItems, {params: {currencyProfileId: this.newInvoice.currencyProfileId, pageSize: MAX_PAGE_SIZE}})
        .then(response => {
          let currencies = [];
          response.data.data.data.forEach(item => {
            currencies.push(item.quoteCurrency);
          });

          if (this.profile.paymentCurrency) currencies.push(this.profile.paymentCurrency);

          this.currencies = _.uniq(currencies).sort().filter(n => n);
        })
    },

    onBillingPeriodMonthChange() {
      this.newInvoice.billingPeriod = this.newInvoice.billingPeriodMonth.substring(0, 7).replace("-", "/0");
      this.newInvoice.billingPeriodStart = moment(this.newInvoice.billingPeriodMonth).startOf('month').format('YYYY-MM-DD');
      this.newInvoice.billingPeriodEnd = moment(this.newInvoice.billingPeriodMonth).endOf('month').format('YYYY-MM-DD');

      this.$forceUpdate();
    },

    createInvoice(profile, type) {
      this.profile = profile;
      this.type = type;

      this.newInvoice = {
        type: this.type,
        accountCode: this.profile.accountCode,
        originalCurrency: this.profile.paymentCurrency,
        currencyProfileId: this.profile.currencyProfileId,
        items: []
      }

      this.loadCompanyAccrual();

      this.isShowCreateInvoice = true;
    },

    createInvoiceFromApiAccrualReceivable(profile, type, items) {
      this.profile = profile;
      this.type = type;

      this.newInvoice = {
        type: this.type,
        accountCode: this.profile.accountCode,
        originalCurrency: this.profile.paymentCurrency,
        currencyProfileId: this.profile.currencyProfileId,
        items: items
      }
      // this.onBillingPeriodMonthChange();

      this.loadCompanyAccrual();

      this.isShowCreateInvoice = true;
    },

    async submitCreateInvoice() {
      axios.post(apis.erpInvoiceCreateManual, this.newInvoice)
        .then(response => {
          let invoice = response.data.data;
          // Wait for all accruals to be added to new invoice
          return Promise.all(this.invoiceAccruals.map(async (accrual) => {
            return axios.post(apis.erpInvoices + '/' + invoice.id + '/accruals', { accrualNumber: accrual.accrualNumber });
          }))
            .then(() => {
              return invoice;
            });
        })
        .then(invoice => {
          this.isShowCreateInvoice = false;
          this.editInvoice(invoice);

          EventBus.$emit("invoice-updated");
        })
        .catch((e) => {
          this.promptError(e);
        });
    },

    loadCompanyAccrual() {
      this.isLoading = true;
      return axios.get(apis.erpAccruals, {params: {accountCode: this.profile.accountCode, pageSize: MAX_PAGE_SIZE}})
        .then(response => {
          this.companyAccruals = response.data.data.data;
        })
        .finally(() => {
          this.isLoading = false;
        })
    },

    addAccrual(accrual) {
      if (!accrual) return;
      if (this.invoiceAccruals.find((a) => a.id === accrual.id)) return;

      this.invoiceAccruals.push(accrual);
    },

    removeAccrual(i) {
      this.invoiceAccruals.splice(i, 1);
    },

    getGroupSum(result, k) {
      let amount = 0;

      // Get the sum of amount for each group
      for (let key in result) {
        let group = result[key];
        amount += group[k];
      }

      return amount;
    }
  }
}
</script>
